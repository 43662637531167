import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import * as JobPostsStyles from "./jobPosts.module.css"
import { Container, Row, Col, Card } from "react-bootstrap"
import { Fragment } from "react"
import { ConeStriped } from "react-bootstrap-icons"

const PostsWrapper = ({ category, subcategory }) => (
  <StaticQuery
    query={graphql`
      query {
        allMysqlPosts(sort: { fields: publish_at, order: DESC }) {
          nodes {
            mysqlId
            slug
            category_id
            type_id
            vacancy_type
            vacancy_subtitle
            vacancy_the_role
            vacancy_the_company
            vacancy_salary_amount
            vacancy_salary
            vacancy_reference
            vacancy_location
            vacancy_benefits
            work_in_uk
            vacancy_candidate_requirements
            enabled
            featured
          }
        }
      }
    `}
    render={data => {
      const posts = data.allMysqlPosts.nodes

      let showPosts = false

      const postsFilterd = posts.map((post, i) => {
        if (
          post.enabled === 1 &&
          // post.featured === 1 &&
          post.category_id === category &&
          post.vacancy_type === subcategory
        ) {
          showPosts = true
          // console.log("matched")
        }
      })

      //   console.log(posts)

      // console.log(postsFilterd)

      // console.log(!postsFilterd)
      //   console.log("showPosts: " + showPosts)

      // console.log(category)
      // console.log(subcategory)

      // console.log(!postsFilterd)
      console.log("showPosts: " + showPosts)

      if (showPosts) {
        return (
          <>
            {posts.map((post, i) => (
              <React.Fragment key={i}>
                {post.enabled === 1 &&
                // post.featured === 1 &&
                post.category_id === category &&
                post.vacancy_type === subcategory ? (
                  <Col sm={4}>
                    <Link
                      style={{ textDecoration: "none", color: "#ce5f26" }}
                      to={`../` + post.slug}
                    >
                      <Card className={JobPostsStyles.card}>
                        <Card.Header className={JobPostsStyles.cardTextHeader}>
                          {post.vacancy_type}
                        </Card.Header>
                        <Card.Body className={JobPostsStyles.cardText}>
                          <Card.Text>{post.vacancy_subtitle}</Card.Text>
                        </Card.Body>
                        <Card.Header
                          className={JobPostsStyles.cardTextLocation}
                        >
                          {post.vacancy_location}
                        </Card.Header>
                        <Card.Header className={JobPostsStyles.cardTextSalary}>
                          {post.vacancy_salary_amount}
                        </Card.Header>
                      </Card>
                    </Link>
                  </Col>
                ) : null}
              </React.Fragment>
            ))}
          </>
        )
      } else {
        return (
          <div className={JobPostsStyles.pageTextNoVac}>
            <p>There are no vacancies in this category currenly.</p>
            <p>
              {" "}
              Register{" "}
              <a href="https://cms.miseenplace.co.uk/candidates">here</a> and we
              will let you know when a vacancy in this category becomes
              available
            </p>
          </div>
        )
      }
    }}
  />
)

const JobPostsFilterSub = ({ category, subcategory }) => {
  //   console.log(category)
  return (
    <>
      <main>
        <Container className={JobPostsStyles.postsContainer}>
          <Row>
            <PostsWrapper category={category} subcategory={subcategory} />
          </Row>
        </Container>
      </main>
    </>
  )
}

export default JobPostsFilterSub
