import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as jobNavStyles from "./jobsNavChefs.module.css"
import { Navbar, Nav } from "react-bootstrap"
import { SquareFill } from "react-bootstrap-icons"

const JobsNavChefs = ({ link }) => {
  return (
    <>
      <div>
        <Navbar expand="lg" className="justify-content-center">
          <Nav fill className="mx-auto parent">
            <Link to="/executive-chef">
              <div
                className={
                  link === "executive-chef"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                EXECUTIVE CHEF
              </div>
            </Link>
            <Link to="/head-chefs">
              <div
                className={
                  link === "head-chefs"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                HEAD CHEF
              </div>
            </Link>
            <Link to="/sous-chefs">
              <div
                className={
                  link === "sous-chef"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                SOUS CHEF
              </div>
            </Link>
            <Link to="/chefs-de-partie">
              <div
                className={
                  link === "chefs-de-partie"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                CHEF DE PARTIE
              </div>
            </Link>
          </Nav>
        </Navbar>
      </div>
      <div>
        <Navbar expand="lg" className="justify-content-center">
          <Nav fill className="mx-auto parent">
            <Link to="/chef-pastry">
              <div
                className={
                  link === "chef-pastry"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                PASTRY CHEFS /BAKERS
              </div>
            </Link>
            <Link to="/private-chefs">
              <div
                className={
                  link === "private-chefs"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                PRIVATE CHEFS
              </div>
            </Link>
            <Link to="/chef-manager">
              <div
                className={
                  link === "chef-manager"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                CHEF MANAGER
              </div>
            </Link>
            <Link to="/butcher">
              <div
                className={
                  link === "butcher"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                BUTCHER
              </div>
            </Link>
          </Nav>
        </Navbar>
      </div>
    </>
  )
}

JobsNavChefs.propTypes = {
  siteTitle: PropTypes.string,
}

JobsNavChefs.defaultProps = {
  siteTitle: ``,
}

export default JobsNavChefs
